<template>
  <div class="clientHome" ref="clientHome" :style="{'min-height': isIpad}">
    <div class="cover" v-if="cover" @click="closeCover">
      <img v-if="!isAliApp" class="cover-img"
           :src="`https://cdn.cailu88.com/jingxuanshi/client_home_${this.terminal == 'android' ? 'guide%20' : 'guide_safari'}.png`"/>

      <div v-else>
        <img class="cover-img-ali" :src="'https://cdn.cailu88.com/jingxuanshi/client_cover_and2@2x.png'"/>
        <img class="cover-img-button" :src="'https://cdn.cailu88.com/jingxuanshi/client_cover_button@2x.png'" @click="closeCover"/>
      </div>
    </div>
    <div class="mark" v-if="!click">
      <van-loading type="spinner" vertical>加载中...</van-loading>
    </div>
    <div class="footer-fix" v-if="isAliApp">
      <div>
        <van-button class="button" @click="handleCopy">复制链接至浏览器下载鲸选师</van-button>
      </div>
    </div>
    <div class="top">
      <!-- <div>{{ua}}</div> -->
      <img class="top-logo" src="https://cdn.cailu88.com/jingxuanshi/client_home_logo_2.png"/>
      <div class="top-left"></div>
    </div>
    <div class="empty-box">
      <van-button class="button" type="default" @click="handleDownload">{{ buttonText }}</van-button>
    </div>
    <div class="bottom" :style="{'position' : (height ? 'absolute' : '')}">
      <img class="bottom-phone" src="https://cdn.cailu88.com/jingxuanshi/client_home_phone_2@2x.png"/>
    </div>
    <!--    <div class="footer">-->
    <!--      <p class="pic-text">-->
    <!--        <span>Copyright © 2019-2020 杭州聚心选网络科技有限公司版权所有</span><br/>-->
    <!--        <a href="http://www.beian.gov.cn/portal/index.do" target="view_window">浙ICP备20004143号</a>-->
    <!--      </p>-->
    <!--    </div>-->
  </div>
</template>
<script>
import Vue from 'vue'
import { Button, Loading, Toast } from 'vant'

Vue.use(Button)
Vue.use(Loading)
Vue.use(Toast)

export default {
  name: 'clientHome',
  data () {
    return {
      ua: {},
      click: true,
      cover: false,                 // 遮罩
      isAliApp: false,              // 支付宝小程序下安卓环境
      isWx: false,
      isIpad: '100vh',
      terminal: '',
      // currentEnvironment: '',
      height: true,
      downloadUrl: 'https://cdn.cailu88.com/jingxuanshi/apk/jxs_release_0.6.1.apk',           //安卓安装包url
      appsUrl: 'https://apps.apple.com/cn/app/id1533950475',                                   // appstore
      buttonText: '立即下载',
    }
  },
  mounted () {
    this.isWX()
    this.screenWidth()
    window.addEventListener('resize', this.screenWidth)
    this.$once('hook:beforeDestroy', () => {              // 通过$on,$once使用hook监听vue生命周期函数，进行监听销毁
      window.removeEventListener('resize', this.screenWidth)
    })

  },
  methods: {
    // 立即下载
    handleDownload () {
      let u = window.navigator.userAgent
      let isIOSApp = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)  // ios终端
      let isAndroidApp = (u.indexOf('Android') > -1) // android
      if (isIOSApp) {
        this.terminal = 'ios'
        // this.buttonText = '敬请期待';
        window.open(this.appsUrl)
        return
      } else if (isAndroidApp) {
        if (this.isAliApp) {
          this.handleCopy();
        } else if (this.isWx){
          // window.open('https://a.app.qq.com/o/simple.jsp?pkgname=com.duilu.jxs')
          window.open('https://cdn.cailu88.com/jingxuanshi/apk/jxs_release_0.6.1.apk')
        } else{
          this.terminal = 'android'
          // window.open(this.downloadUrl)
          window.open('https://cdn.cailu88.com/jingxuanshi/apk/jxs_release_0.6.1.apk')
        }
      }
    },
    screenWidth () {
      this.isIpad = '100vh'
      let width = document.documentElement.clientWidth
      let height = document.documentElement.clientHeight
      // if ((410 < width && width < 500) || (width<400 && height>600)) {
      //   this.isIpad = '110vh';
      // }
      // if (width > 500){
      //   this.isIpad = '140vh';
      // }
      if (height < 600) {
        this.height = false
      } else {
        this.height = (width < 350 && width > 310) || (width > 365 && height < 680) ? false : true
      }
      if (height > 600 && width > 500) {
        this.height = false
      }
      // if (width > 1000){
      //   this.isIpad = '300vh';
      // }
      // if (width > 1500){
      //   this.isIpad = '600vh';
      // }
    },

    // 复制链接
    handleCopy(){
      this.$copyText(this.downloadUrl).then(() => {
        Toast.success('复制成功');
      }, () => {
        Toast.fail('复制失败');
      })
    },

    // 判断是否在微信环境
    isWX () {
      let u = window.navigator.userAgent
      this.ua = u
      let isIOSApp = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)  // ios终端
      let isAndroidApp = (u.indexOf('Android') > -1) // android
      if (/MicroMessenger/gi.test(u)) {        // 微信
        this.cover = isAndroidApp ? true : false;
        this.isWx = true;
      } else if (/MQQBrowser/gi.test(u) && isAndroidApp) {                        // qq安卓
        if (/QQ/gi.test(u.split('MQQBrowser'))) {     //
          this.cover = true;
        } else {
          this.cover = false;
        }
      } else if (/QQ/gi.test(u) && isIOSApp) {                       // qq ios
        this.cover = false;
      } else if (/AliApp/gi.test(u)) {
        this.cover = true;
        if (isAndroidApp){
          this.isAliApp = true;
          this.buttonText = '获取下载链接';
        } else if (isIOSApp){
          this.cover = false;
        }
      }
      this.terminal = isIOSApp ? 'ios' : isAndroidApp ? 'android' : '';
    },

    // 收起遮罩
    closeCover () {
      this.cover = false;
    },
  }
}
</script>
<style lang="less" scoped>
.clientHome {
  position: relative;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  background: #F8E94E;

  .cover {
    position: absolute;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.8);
    width: 100%; /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
    height: 100%;
    filter: alpha(opacity=100); /*设置透明度为100%*/
    opacity: 1; /*非IE浏览器下设置透明度为100%*/
    display: block;
    z-Index: 999;

    .cover-img {
      width: 100%;
    }

    .cover-img-ali{
      margin-top: 10px;
      width: 302px;
    }
    .cover-img-button{
      margin-top: 31px;
      width: 176px;
    }
  }

  .footer-fix{
    position: fixed;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    div{
      .button{
        width: 300px;
        background: #FFEA1C;
        box-shadow: 0px 2px 4px 0px #FFEA1C;
        border-radius: 25px;
        border: none;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 22px;
      }
    }
  }

  .mark {
    width: 160px;
    height: 80px;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%);
  }

  .top {
    position: relative;
    padding-top: 8px;

    .top-logo {
      width: 100%;
    }

    .top-left {
      position: absolute;
      right: 0;
      top: 0;
      width: 79px;
      height: 89px;
      background: linear-gradient(229deg, #FDC005 0%, #FA8C02 100%);
      opacity: 0.5;
      filter: blur(19px);
    }
  }

  .empty-box {
    width: 100%;
    height: 80px;
  }

  .button {
    margin: 15px auto;
    width: 240px;
    height: 50px;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    background: #FFFFFF;
    border-radius: 25px;
    border: none;
  }

  .bottom {
    //position: absolute;
    bottom: 0;
    width: 100%;
    line-height: 0;

    .bottom-phone {
      width: 100%;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #F8E94E;

    .pic-text {
      text-align: center;
      font-size: 10px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #291306;
      line-height: 20px;

      & > a {
        color: #AF7018;
        text-decoration: none;
      }

      & > a:hover {
        cursor: pointer;
        color: black;
      }
    }
  }
}
</style>
